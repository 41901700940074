export interface IAppUserContract {
    id?: number;
    userid?: string;
    dateContract?: string;
    methodPayment?: number;
    bank?: string;
    bankNumber?: string;
}
export class AppUserContract implements IAppUserContract {
    constructor(
        public id?: number,
        public userid?: string,
        public dateContract?: string,
        public methodPayment?: number,
        public bank?: string,
        public bankNumber?: string,
    ) { }
}
