export interface IAppConfig {
    id?: number;
    code?: string;
    name?: string;
    value?: string;
    note?: string;
}
export class AppConfig implements IAppConfig {
    constructor(public id?: number, public code?: string, public name?: string, public value?: string, public note?: string) { }
}
