import { SERVER_FILE_URL } from 'src/app/utils/app.constants';

export default class Adapter {
  loader;
  url;
  xhr;
  constructor(loader, url) {
    this.loader = loader;
    this.url = url;
    this.xhr = new XMLHttpRequest();
  }

  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject, this.loader.file);
      this._sendRequest(this.loader.file);
    });
  }

	/**
	 * Aborts the upload process.
	 *
	 * @see module:upload/filerepository~UploadAdapter#abort
	 */
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();

    xhr.open('POST', this.url, true);
    // xhr.responseType = 'json';
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericError = 'Cannot upload file:' + ` ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericError));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;
      console.log(response);
      if (!response) {
        return reject(response && response.error && response.error.message ? response.error.message : genericError);
      }

      resolve({
        default: SERVER_FILE_URL + response
      });
    });

    // Upload progress when it's supported.
    /* istanbul ignore else */
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file) {
    // Prepare form data.
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    // data.append( 'ckCsrfToken', getCsrfToken() );
    this.xhr.setRequestHeader('x-reportProgress', true);
    this.xhr.setRequestHeader('responseType', 'text');
    // Send request.
    this.xhr.send(formdata);
  }
}

