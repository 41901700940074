import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SERVER_API_URL } from '../utils/app.constants';
import { Observable } from 'rxjs/internal/Observable';
import { PostOffices } from '../model/post-offices.model';

@Injectable({ providedIn: 'root'})
export class PostOfficesService {

    public resourceUrl = SERVER_API_URL + 'api';
    
    constructor(protected http: HttpClient) {}

    getAll(): Observable<HttpResponse<PostOffices[]>> {
        return this.http.get<PostOffices[]>(this.resourceUrl + '/postOffice/all', { observe: 'response' });
    }
}