export interface IAppUserDocument {
    id?: number;
    userid?: string;
    link?: string;
    type?: number;
}
export class AppUserDocument implements IAppUserDocument {
    constructor(
        public id?: number,
        public userid?: string,
        public link?: string,
        public type?: number,
    ) { }
}
