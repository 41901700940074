import { AppUserContract } from './app-user-contract.model';
import { AppUserDocument } from './app-user-document.model';

export interface IAppUser {
    id?: number;
    login?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    phone?: string;
    passpostId?: string;
    companyName?: string;
    position?: string;
    taxCode?: string;
    status?: number;
    userType?: number;
    address?: string;
    sex?: number;
    imageUrl?: string;
    activated?: number;
    langKey?: string;
    birthday?: string;
    resetDate?: Date;
    appUserContract?: AppUserContract;
    appUserDocument?: AppUserDocument;
}
export class AppUser implements IAppUser {
    constructor(
        public id?: number,
        public login?: string,
        public password?: string,
        public firstName?: string,
        public lastName?: string,
        public fullName?: string,
        public email?: string,
        public phone?: string,
        public passpostId?: string,
        public companyName?: string,
        public position?: string,
        public taxCode?: string,
        public status?: number,
        public userType?: number,
        public address?: string,
        public sex?: number,
        public imageUrl?: string,
        public activated?: number,
        public langKey?: string,
        public birthday?: string,
        public resetDate?: Date,
        public appUserContract?: AppUserContract,
        public appUserDocument?: AppUserDocument,
    ) { }
}
